// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")


// DOES NOT WORK, but puts them into page
// import VectorCanvas from "jqvmap/dist/jquery.vmap.min.js";
// import JQVMap from "jqvmap/dist/jquery.vmap.min.js";
// window.VectorCanvas = VectorCanvas;
// window.JQVMap = JQVMap;
// import "jqvmap/dist/maps/jquery.vmap.world.js";
window.VIMEO_REGEX = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]*)/i;
window.YOUTUBE_REGEX = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)(?!user\/|channel\/|c\/)([\w\-]+)(\S+)?$/m;

window.$ = $;
window.jQuery = $;
window.Popper = Popper;
window.Chart = Chart;
window.Charts = Charts;
window.Dropzone = Dropzone;
window.toastr = toastr;
// window.JQVMap = JQVMap;
window.moment = moment;
window['moment-range'] = momentrange;
window.domFactory = domFactory;
window.List = List;
window.fullcalendar = fullcalendar;
window.dragula = dragula;
window.TimeMe = TimeMe;

require("popper.js/dist/umd/popper.js")
require("bootstrap")
require("simplebar")
// require("dom-factory")
require("material-design-kit")
require("jquery-mask-plugin")
require("select2")
// require("moment")
// require("moment-range")
require("flatpickr")
// require("jqvmap/dist/jquery.vmap.min.js")
// require("jqvmap/dist/maps/jquery.vmap.world.js")
// require("stack/vector-maps/mall-map.js")
// require("stack/vector-maps/gdp-data.js")
// require("dropzone")
require("list.js/dist/list.min.js")
// require("toastr")
// require("dragula")
// require("fullcalendar")
require("quill")
require("daterangepicker")
require("stack/settings.js")
require("stack/plugins/charts.js")
require("stack/plugins/chartjs-rounded-bar.js")
require("stack/plugins/daterangepicker.js")
require("stack/plugins/dragula.js")
require("stack/plugins/dropzone.js")
require("stack/plugins/flatpickr.js")
require("stack/plugins/fullcalendar.js")
require("stack/plugins/list.js")
require("stack/plugins/quill.js")
// require("stack/plugins/select2.js")
require("stack/plugins/toastr.js")
// require("stack/plugins/vector-maps.js")
require("stack/core-scripts.js")
// require("stack/cable.js")
require("stack/index.js")

require("chartkick")
require("chart.js")

require("packs/custom.js")

require("packs/add_fields")
require("packs/remove_fields")

require("packs/trix_video")

import "controllers"
