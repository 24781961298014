import consumer from "./consumer"

document.addEventListener('chat-index', () => {
  let appearance_channel = consumer.subscriptions.create("AppearanceChannel", {
    // Called once when the subscription is created.
    initialized() {
      this.update = this.update.bind(this);
    },

    // Called when the subscription is ready for use on the server.
    connected() {
      console.log("connected to chat index");
      this.update();
      this.perform("check_online");
    },

    // Called when the WebSocket connection is closed.
    disconnected() {
      this.perform("unsubscribed");
    },

    // Called when the subscription is rejected by the server.
    rejected() {
      this.perform("unsubscribed");
    },

    update() {
      console.log("appear update");
      if ($('#user-chat-list-form').length < 1) {
        console.log("update unsub");
        this.perform("unsubscribed");
        this.unsubscribe();
      }
      this.documentIsActive ? this.appear() : this.away();
    },

    appear() {
      // Calls `AppearanceChannel#appear` on the server.
      console.log("appear ch appear");
      this.perform("appear");
    },

    away() {
      // Calls `AppearanceChannel#away` on the server.
      this.perform("away");
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      if (data.check_online == "true") {
        let online_users = JSON.parse(data.online_users);
        for (var i = online_users.length - 1; i >= 0; i--) {
          let user_btn = $("button.chat-user[data-id=" + online_users[i]+ "]");
          if (!user_btn.hasClass("btn-outline-success")) {
            user_btn.removeClass(["btn-outline-warning", "btn-outline-secondary"]);
            user_btn.addClass("btn-outline-success");
          }
        }
      } else {
        let status_class = "";
        if (data.chat_status == "0") {
          status_class = "btn-outline-secondary";
        } else if (data.chat_status == "1") {
          status_class = "btn-outline-success";
        } else if (data.chat_status == "2") {
          status_class = "btn-outline-warning";
        }
        if ($("button.chat-user[data-id=" + data.id + "]").length < 1) {
          $('#user-list').append(`
            <button type="button" class="chat-user btn ${ status_class } border-none text-left" data-id="${ data.id }"> 
              ${ data.name }
            </button>
          `)
        }
        $("button.chat-user[data-id=" + data.id + "]").removeClass(["btn-outline-success", "btn-outline-warning", "btn-outline-secondary"]);
        $("button.chat-user[data-id=" + data.id + "]").addClass(status_class);
        $("button.chat-user[data-id=" + data.id + "]").find(".chat-last-impression").attr("title", data.last_impresssion_title );
        $("button.chat-user[data-id=" + data.id + "]").find(".chat-last-impression").html(this.truncate_30(data.last_impresssion_title));
      }
    },

    get documentIsActive() {
      return document.visibilityState === "visible" || document.hasFocus()
    },

    truncate_30(text) {
      if (text.length > 30) {
          return text.substring(0, 30) + '...'
      }
      return text
    },
  })

  $(window).bind("turbolinks:load ready", function(){
    appearance_channel.update();
  });

  $('button').bind('click', function(){
    if ($(this).hasClass("chat-user")) {
      appearance_channel.update();
    }
  })

})