String.prototype.shuffle = function () {
    var a = this.split(""),
        n = a.length;

    for(var i = n - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join("");
}

jQuery.cachedScript = function( url, options ) {
 
  // Allow user to set any option except for dataType, cache, and url
  options = $.extend( options || {}, {
    dataType: "script",
    cache: true,
    url: url
  });
 
  // Use $.ajax() since it is more flexible than $.getScript
  // Return the jqXHR object so we can chain callbacks
  return jQuery.ajax( options );
};

$(document).ready(function() {
	var divs = document.getElementsByClassName('trix-content');
	for (var i = 0; i < divs.length; i++) {
	    var a = divs[i].getElementsByTagName('a');
	    for (var j = 0; j < a.length; j++) {
	    	if (a[j].dataset['remote'] != 'true') {
		        a[j].target = '_blank';
		    }
	    }
	}
	
	if (window.hasOwnProperty('USER_SIGNED_IN') && USER_SIGNED_IN == "true") {
		if ( window.hasOwnProperty( "time_page_name" ) ) {
			console.log("TimeMe already initialized.");
		} else {
			console.log("TimeMe init.");
			var time_page_name = "spiorails";
			TimeMe.initialize({
				currentPageName: time_page_name, // current page
				idleTimeoutInSeconds: 30 // seconds
		    })
		    if (add_time_to_user) {
		    	clearInterval(add_time_to_user);
		    }
		    var old_add_time  = 0;
		    var send_url = ROOT_URL + "add_usage_time";
		    var add_time_to_user = setInterval(function (event) {
		    	if (old_add_time == 0) {
		    		old_add_time = TimeMe.getTimeOnCurrentPageInSeconds();
		    		var send_time = old_add_time;
		    	} else {
		    		var new_time = TimeMe.getTimeOnCurrentPageInSeconds();
		    		var send_time = new_time - old_add_time;
		    		old_add_time = new_time;
		    	}
		    	if (send_time > 0) {
					$.ajax({
			          type: "get",
			          url: send_url,
			          data: { add_seconds: send_time },
			          dataType: "json",
			          success: function(data) {
			            console.log("Time Log Success");
			          }
			        }) ;
				}
			}, 30000);
		}
	}

	document.addEventListener("turbolinks:load", function() {
	  $('.select2-container').remove() //remove the select2 container DOM
	  $('.select2-input').select2(); // Select2 will just reinit the DOM
	})
})

$(document).on('turbolinks:load', function(){
  	$('[data-toggle="tooltip"]').tooltip();

  	$('#nav-search-btn').on('click', function(){
	  	setTimeout(function() { 
	  		let search_input = $('#search-navbar #nav_search_query');
	  		let search_length = search_input[0].value.length;
	
	  		search_input[0].setSelectionRange(search_length, search_length);
	  		$('#search-navbar #nav_search_query').focus(); 
	  	}, 100);
	})

	if (window.hasOwnProperty('NO_USER') && NO_USER == "true") {
		$(".opt-shuffle").each(function(){
			$(this).addClass('blur');
			$(this).text($(this).text().shuffle());
		})
	}
});