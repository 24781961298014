import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
  	this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
  	var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
		               navigator.userAgent &&
		               navigator.userAgent.indexOf('CriOS') == -1 &&
		               navigator.userAgent.indexOf('FxiOS') == -1;
		var menuEl = document.body
		if(isSafari) {
	  	menuEl = document.getElementById("mention-product-id")
	  }
	  this.tribute = new Tribute({
      allowSpaces: true,
  		lookup: function (person, mentionText) {
		    return person.name + " " + person.email + " " + person.company_name;
		},
  		menuItemTemplate: function (item) {
    		return item.original.name + " - " + item.original.company_name
  		},
  		values: this.fetchUsers,
  		menuContainer: menuEl,
  	})
  	this.tribute.attach(this.fieldTarget)
  	this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  	this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
	}

  fetchUsers(text, callback) {
    let product_id = document.getElementById("mention-product-id").dataset.product
    fetch(`/mentions.json?query=${text}&product_id=${product_id}`)
      .then(response => response.json())
      .then(users => callback(users)) 
      .catch(error => callback([]))
  }

  replaced(e) {
  	let mention = e.detail.item.original
  	let attachment = new Trix.Attachment({
  		sgid: mention.sgid,
  		content: mention.content
  	})
  	this.editor.insertAttachment(attachment)
  	this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
  	let position = this.editor.getPosition()
    let tributeLength = endPos - startPos
    let trixStartPos = position - tributeLength
    let trixEndPos = position
    this.editor.setSelectedRange([trixStartPos, trixEndPos])
    this.editor.deleteInDirection("backward")
  }
}
