import consumer from "./consumer"

document.addEventListener('chat-index', () => {
	consumer.subscriptions.create("ChatNotificationsChannel", {
		connected() {
			console.log("notifications connected")
		},
	  received(data) {
	  	console.log("notice received")
	    if ($('#user-chat-list-form').length < 1) {
	      this.unsubscribe();
	    } else {
	    	if ($('#receiver_id').val() != data.sender_id) {
		    	$("button.chat-user[data-id=" + data.sender_id + "]").prepend(`<span class="badge badge-success">!</span>`);
		    }
	    }
	  }
	})
})