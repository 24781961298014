import consumer from "./consumer"

var chat_sub = null;

document.addEventListener('chat-connect', () => {
	console.log('chat connected');
	if ($('#chat_id').length > 0) {
		let chat_id = document.getElementById("chat_id").value;

		if (chat_sub != null) {
			chat_sub.unsubscribe();
		}

	  chat_sub = consumer.subscriptions.create({channel: "MessageChannel", chat_id: chat_id}, {
	    connected() {
	      // Called when the subscription is ready for use on the server
	      console.log("Channel Connected!!!");
	    },

	    disconnected() {
	      // Called when the subscription has been terminated by the server
	    },

	    received(data) {
	      // Called when there's incoming data on the websocket for this channel
	      console.log($('#receiver_id').val());
	      console.log(data.sender_id);
	      
	      if ($('#messages').length > 0 && data.html != "DELETE") {
		      let message_box = document.getElementById("messages");
		      message_box.innerHTML += data.html;
		      document.getElementById("message").scrollIntoView();
		      document.getElementById("message").value = "";
		    } else if (data.html == "DELETE") {
		    	document.getElementById("message-id-" + data.message_id).remove();
		    } else {
		    	chat_sub.unsubscribe();
		    }

		    if ($('#receiver_id').val() == data.sender_id) {
	      	this.perform("mark_read", {message_id: data.message_id});
	      } else {
	      	document.getElementById("message-id-" + data.message_id).innerHTML += `<div class="flex-action-container"><a data-confirm="Are you sure?" data-remote="true" rel="nofollow" data-method="delete" href="/messages/${data.message_id}.js">Delete</a></div>`;
	      	document.getElementById("message").scrollIntoView();
	      }
	    }
	  });
	}
});